import React, { useState, useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import "../css/style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { ReactLogo } from "../components/ReactLogo";
import { Modal, Button } from "react-bootstrap";

const Home = () => {
  const [files, setFiles] = useState([]);
  const [emisor, setEmisor] = useState("");
  const [destinatario, setDestinatario] = useState("");
  const [mensaje, setMensaje] = useState("");
  const [titulo, setTitulo] = useState("");
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [result, setResult] = useState(null);
  const [showModal, setShowModal] = useState(true);
  const [acceptedPolicies, setAcceptedPolicies] = useState(false);

  const onDrop = useCallback((acceptedFiles) => {
    setFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/*,application/pdf",
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!acceptedPolicies) {
      alert("Debe aceptar las políticas para continuar.");
      return;
    }
    setLoading(true);
    setResult(null);
    setProgress(0);

    if (files.length === 0) {
      setLoading(false);
      setResult({
        success: false,
        message: "Debe anexar al menos un documento",
      });
      return;
    }

    const formData = new FormData();
    files.forEach((file) => {
      formData.append("files", file);
    });

    try {
      const response = await axios.post(
        `https://transferback.azurewebsites.net/api/CargaDocumentos/${emisor}/${destinatario}/${titulo}/${mensaje}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            const total = progressEvent.total;
            const currentProgress = Math.round(
              (progressEvent.loaded * 100) / total
            );
            setProgress(currentProgress);
          },
        }
      );

      if (response.status === 200) {
        setResult({ success: true, message: "Documentos enviados con éxito" });
      } else {
        throw new Error("Error al enviar documentos");
      }
    } catch (error) {
      setResult({
        success: false,
        message:
          error.response?.data?.message ||
          "Ha ocurrido un problema al enviar los documentos.",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleAcceptPolicies = () => {
    setAcceptedPolicies(true);
    setShowModal(false);
  };

  const handleCancelPolicies = () => {
    alert(
      "Debe aceptar nuestra política de tratamiento de datos para hacer uso de este servicio."
    );
  };

  useEffect(() => {
    if (!acceptedPolicies) {
      setShowModal(true);
    }
  }, [acceptedPolicies]);

  return (
    <>
      <Modal
        show={showModal}
        onHide={() => setShowModal(true)}
        backdrop="static"
        keyboard={false}
        className="custom-modal-width"
      >
        <Modal.Header>
          <Modal.Title>Políticas de Seguridad</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Al dar click en <strong>Acepto</strong>, autorizo de manera expresa
            e inequívoca, en mi calidad de titular(es) del dato, el tratamiento
            de mis datos personales a el INSTITUTO COLOMBIANO DE NORMAS TÉCNICAS
            Y CERTIFICACIÓN y/o a quien le sean cedidos sus derechos, para
            tratar mi información personal y/o los datos personales, de
            conformidad con lo establecido en la Ley 1581 de 2012 y demás
            normatividad reglamentaria vigente, y en lo descrito en la{" "}
            <a
              href="https://www.icontec.org/documentos-corporativos/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Política de Privacidad y Tratamiento de Datos Personales
            </a>{" "}
            y el Aviso de Privacidad disponibles en la página web{" "}
            <a
              href="https://www.icontec.org"
              target="_blank"
              rel="noopener noreferrer"
            >
              www.icontec.org
            </a>
            .
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancelPolicies}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleAcceptPolicies}>
            Acepto
          </Button>
        </Modal.Footer>
      </Modal>

      <div>
        <meta charSet="utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Booking Form HTML Template</title>
        <link
          href="https://fonts.googleapis.com/css?family=Montserrat:400,700"
          rel="stylesheet"
        />
        <link type="text/css" rel="stylesheet" href="css/bootstrap.min.css" />
        <link type="text/css" rel="stylesheet" href="css/style.css" />
        <div id="booking" className="section">
          <ReactLogo />
          <div className="section-center">
            <div className="container">
              <div className="row">
                <div className="col-md-7 col-md-push-5">
                  <div className="booking-cta">
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <h1>ICON-TRANSFER</h1>
                    <p>
                      Bienvenido al Portal de Transferencia de Archivos de
                      Icontec, nuestra solución permite intercambiar archivos de
                      hasta 2 GB de manera temporal, asegurando transferencias
                      rápidas y seguras mediante enlaces que estarán vigentes
                      automáticamente durante el día que fueron cargados los
                      archivos en la herramienta.
                      <br></br>
                      <strong>Nota: </strong>Recuerde que los documentos
                      enviados tendrán una caducidad de 7 días.
                    </p>
                  </div>
                </div>
                <div className="col-md-4 col-md-pull-1">
                  <div className="booking-form">
                    <form onSubmit={handleSubmit}>
                      <div className="form-group">
                        <span className="form-label">
                          Arrastre sus documentos
                        </span>

                        <div {...getRootProps({ className: "dropzone" })}>
                          <input {...getInputProps()} />
                          <p>
                            Arrastre y suelte algunos archivos en este espacio,
                            o haga clic <span className="click-link">aquí</span>{" "}
                            para seleccionar archivos
                          </p>
                        </div>
                      </div>
                      <div className="form-group">
                        {files.length > 0 && (
                          <div>
                            <h4>Archivos seleccionados:</h4>
                            <ul>
                              {files.map((file, index) => (
                                <li key={index}>{file.name}</li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </div>
                      {loading && (
                        <div className="form-group">
                          <label>Subiendo: {progress}%</label>
                          <div className="progress">
                            <div
                              className="progress-bar"
                              role="progressbar"
                              style={{ width: `${progress}%` }}
                              aria-valuenow={progress}
                              aria-valuemin="0"
                              aria-valuemax="100"
                            >
                              {progress}%
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="form-group">
                            <span className="form-label">Emisor</span>
                            <input
                              className="form-control"
                              type="email"
                              placeholder="emisor"
                              value={emisor}
                              onChange={(e) => setEmisor(e.target.value)}
                              required
                            />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <span className="form-label">Destinatario</span>
                            <input
                              className="form-control"
                              placeholder="destinatario"
                              type="email"
                              value={destinatario}
                              onChange={(e) => setDestinatario(e.target.value)}
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="form-group">
                            <span className="form-label">Título</span>
                            <input
                              className="form-control"
                              type="text"
                              placeholder="titulo"
                              value={titulo}
                              onChange={(e) => setTitulo(e.target.value)}
                              required
                            />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <span className="form-label">Mensaje</span>
                            <input
                              className="form-control"
                              type="text-area"
                              placeholder="mensaje"
                              value={mensaje}
                              onChange={(e) => setMensaje(e.target.value)}
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-btn">
                        <button type="submit" className="submit-btn">
                          {loading ? "Cargando..." : "Compartir"}
                        </button>
                      </div>
                    </form>

                    {result && (
                      <div
                        className={`alert ${
                          result.success ? "alert-success" : "alert-danger"
                        } mt-3`}
                      >
                        {result.message}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
